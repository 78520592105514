.modal {
  position: relative;
  width: auto;
  height: auto;
  max-width: 700px;
  max-height: 600px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background: #111;
  overflow: hidden;
  border-radius: 18px;
  opacity: 0.9;
  transition: all 800ms ease-in-out 4s;
  animation: fadeIn 800ms;
}
.modal__poster-img {
  width: 100%;
  height: auto;
}
.modal__content {
  padding: 40px;
}
.modal__title {
  padding: 0;
  font-size: 40px;
  margin: 16px 0;
}
.modal__details {
  font-weight: 600;
  font-size: 18px;
}
.modal__overview {
  font-size: 20px;
  line-height: 1.5;
}
.modal__user-perc {
  color: #46d369;
}
.modal::-webkit-scrollbar {
  display: none;
  visibility: hidden;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.wrapper-modal {
  z-index: -1;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.presentation {
  z-index: 1200;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 1000;
}
@media screen and (max-height: 768px) {
  .wrapper-modal {
    align-items: unset;
    padding-top: 2rem;
  }
  .modal {
    overflow-y: scroll;
  }
}
@media screen and (max-width: 768px) {
  .modal__overview {
    font-size: 16px;
  }
  .modal__details {
    font-size: 16px;
  }
  .wrapper-modal {
    padding: 0;
  }
  .modal {
    overflow-y: scroll !important;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
