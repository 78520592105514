.circular-menu {
    opacity: 1;
    z-index: 1000000000 !important;
    position: fixed !important;
    top: 27em;
    right: 2.5em;
}
.circular-menu .circular-menu-left {
    right: 6em !important;
}
.circular-menu .floating-btn {
    display: block;
    width: 3.1em;
    opacity: 1;
    height: 3.1em;
    border-radius: 50%;
    background-color: #1353b4;
    box-shadow: 0 2px 5px 0 #000;
    color: #fff;
    text-align: center;
    line-height: 3.9;
    cursor: pointer;
    outline: 0;
}
.circular-menu .floating-btn:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.circular-menu .floating-btn i {
    font-size: 1.3em;
    transition: transform 0.4s;
}
.circular-menu:after {
    display: block;
    content: " ";
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    background-color: rgba(15, 109, 138, 0.618);
    transition: all 0.7s ease;
}
.circular-menu .items-wrapper {
    padding: 0;
    margin: 0;
}
.circular-menu .menu-item {
    position: absolute;
    top: 0.2em;
    right: 0.2em;
    z-index: -1;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 1em;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    text-align: center;
    line-height: 3;
    background-color: rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background 0.2s ease;
}
.circular-menu .menu-item:hover {
    background-color: rgba(0, 0, 0, 0.3);
}
.circular-menu.active .floating-btn {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.576);
}
.circular-menu.active:after {
    transform: scale3d(5.5, 5.5, 1);
    transition-timing-function: cubic-bezier(0.68, 1.55, 0.265, 1);
}
.circular-menu.active .menu-item {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.circular-menu.active .menu-item:nth-child(1) {
    transform: translate3d(1em, -7em, 0);
}
.circular-menu.active .menu-item:nth-child(2) {
    transform: translate3d(-4.2em, -6.1em, 0);
}
.circular-menu.active .menu-item:nth-child(3) {
    transform: translate3d(-6.5em, -2em, 0);
}
.circular-menu.active .menu-item:nth-child(4) {
    transform: translate3d(-5.7em, 3em, 0);
}
.circular-menu.active .menu-item:nth-child(5) {
    transform: translate3d(-2em, 6.5em, 0);
}
.circular-menu.active .menu-item:nth-child(6) {
    transform: translate3d(2.5em, 6em, 0);
}
.circular-menu.circular-menu-left {
    right: auto;
    left: 3em;
}
.circular-menu.circular-menu-left .floating-btn {
    background-color: rgba(6, 102, 147, 0.487);
}
.circular-menu.circular-menu-left:after {
    background-color: #1a52ad;
}
.circular-menu.circular-menu-left.active .floating-btn i {
    transform: rotate(90deg);
}
.circular-menu.circular-menu-left.active .menu-item:nth-child(1) {
    transform: translate3d(-1em, -7em, 0);
}
.circular-menu.circular-menu-left.active .menu-item:nth-child(2) {
    transform: translate3d(3.5em, -6.3em, 0);
}
.circular-menu.circular-menu-left.active .menu-item:nth-child(3) {
    transform: translate3d(6.5em, -3.2em, 0);
}
.circular-menu.circular-menu-left.active .menu-item:nth-child(4) {
    transform: translate3d(7em, 1em, 0);
}
