:root {
  --FONT-PRIMARY: monospace;
}
.circleMenu {
  color: white;
}
body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: "Maven Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  scroll-behavior: smooth !important;
 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000000f1;
  color: #fdebeb;
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(10px) saturate(180%);
}

code {
  font-family: "Maven Pro", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.562);
  background-color: #000000;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(189, 187, 187);
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#1e4ac4),
    to(#000000),
    color-stop(0.6, #3557ec)
  );
}
