

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3.3em;
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*ANIMATION*/
  transition-timing-function: ease-in;
  transition: all 0.5s;
}
.nav__black {
  background-color: rgba(0, 0, 0, 0.8);
}
.nav__logo {
  position: sticky;
  left: 40px;
  width: 40px;
  object-fit: contain;
}
.nav__avatar {
  position: sticky;
  right: 40px;
  width: 30px;
  object-fit: contain;
}
