@media screen and (min-width: 480px) {
  #searchbar {
   position: relative;
   right: 100;
    width: auto;
  }
}
.effect-3 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 99;
}
.effect-3 ~ .focus-border:before,
.effect-3 ~ .focus-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #3399ff;
  transition: 0.4s;
}
.effect-3 ~ .focus-border:after {
  left: auto;
  right: 0;
}
.effect-3:focus ~ .focus-border:before,
.effect-3:focus ~ .focus-border:after {
  width: 50%;
  transition: 0.4s;
}
